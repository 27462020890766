$primary: #124e96;
$primary-dark: #173563;
$primary-light: #7695bf;
$primary-lighter: #c8d5e6;
$primary-lightest: #f1f4f9;
$secondary: #d55e96;

$nav-link-hover-bg: #284e7d;

@import "../base/variables";
