@import "../variables";

.bg-gray- {
  @each $level, $color in $grays {
    &#{$level} {
      background-color: $color;
    }
  }
}

.bg-stats- {
  @each $stat, $color in $stats-colors {
    &#{$stat} {
      background-color: $color;
    }
  }
}
