@import "../variables";

.modal-body > .questionnaire-form-container {
  margin: -#{$modal-inner-padding};
}

.card-body > .questionnaire-form-container {
  margin: -#{$card-spacer-x};
}

.questionnaire-form-container {
  background-color: $gray-300;
  border-top: 1px solid $gray-400;
  border-bottom: 1px solid $gray-400;

  .submission {
    padding-top: $modal-inner-padding;
  }

  .questionnaire-title {
    padding: $modal-inner-padding $modal-inner-padding 0;
    color: $gray-700;

    h1 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 0;
    }

    #q_icon {
      position: absolute;
      top: $input-padding-y;
      right: $input-padding-x;
      font-size: 1.175rem;
    }
  }

  .questionnaire-description {
    //font-size: 1.5rem;
    //margin-bottom: 2px;
    color: $gray-700;
    padding-left: $modal-inner-padding;
  }

  .questionnaire-extra-info {
    padding-top: $modal-inner-padding;
    padding-left: $modal-inner-padding;
    padding-right: $modal-inner-padding;
  }

  .section {
    background-color: $white;
    border-top: 1px solid $gray-400;
    border-bottom: 1px solid $gray-400;
    padding: $modal-inner-padding;

    > .title {
      font-weight: bold;
      color: $gray-700;
      font-size: 1.2rem;
    }
  }

  .question {
    margin-bottom: 7px;

    label.title {
      font-weight: 500;
      color: $gray-600;
      //font-size: 1.2rem;
    }
  }
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  grid-column-gap: .75rem;
  grid-row-gap: .75rem;
  grid-auto-rows: minmax(5em, 1fr);

  .button-cell {
    overflow: hidden;
    > * {
      height: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(5, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(7, 1fr);
  }
}