@import "../../../scss/theme/base/variables";

$tokenfield-margin-left: -.25rem !default;
$tokenfield-margin-right: -.25rem !default;

$tokenfield-bg: $input-bg !default;
$tokenfield-padding-x: $input-padding-x !default;
$tokenfield-padding-y: $input-padding-y !default;
$tokenfield-padding: $tokenfield-padding-y $tokenfield-padding-x !default;
$tokenfield-padding-left: $tokenfield-padding-y !default;
$tokenfield-padding-right: $tokenfield-padding-y !default;
$tokenfield-font-size: 1rem !default;
$tokenfield-font-weight: $input-font-weight !default;
$tokenfield-color: $input-color !default;
$tokenfield-border-size: $input-border-width !default;
$tokenfield-border-style: solid !default;
$tokenfield-border-color: $input-border-color !default;
$tokenfield-border-radius: $input-border-radius !default;
$tokenfield-box-shadow: $input-box-shadow !default;
$tokenfield-transition: $input-transition !default;
$tokenfield-background-color: $input-bg !default;

$tokenfield-focus-color: $input-focus-color !default;
$tokenfield-focus-background-color: $input-focus-bg !default;
$tokenfield-focus-border-color: $input-focus-border-color !default;
$tokenfield-focus-outline: 0 !default;
$tokenfield-focus-shadow: $input-focus-box-shadow !default;

$tokenfield-disabled-color: $input-color !default;
$tokenfield-disabled-bg: $input-disabled-bg !default;

$tokenfield-disabled-token-opacity: $btn-disabled-opacity !default;

$tokenfield-token-background-color: $gray-200 !default;
$tokenfield-token-border-color: $tokenfield-border-color !default;
$tokenfield-token-border-size: 1px !default;
$tokenfield-token-padding-x: $tokenfield-padding-x !default;
$tokenfield-token-padding-y: 0 !default;
$tokenfield-token-transition: $transition-base !default;

$tokenfield-token-hover-background-color: $gray-300 !default;
