@import "../variables";

.main-navbar {
  align-items: stretch;
  font-size: 15px;
}

.navbar .navbar-nav {
  > li {
    display: flex;
    align-items: center;

    &.nav-item {
      height: 100%;
      > a {
        display: flex;
        align-items: center;

        > .nav-text {
          margin-left: .75rem;
        }
      }
    }
  }
}

@each $theme, $color in $theme-colors {
  .navbar.bg-#{$theme}.full-background .navbar-nav {
    > li {
      &.nav-item {
        &:hover {
          background-color: theme-color-level($theme, 4);
        }

        &.active {
          background-color: theme-color-level($theme, 2);

          &:hover {
            background-color: theme-color-level($theme, 4);
          }
        }
      }
    }
  }
}

.navbar.sticky-top {
  z-index: 1025;
}

@media only screen and (max-width: map_get($grid-breakpoints, "sm")) {
  .navbar-collapse {
    &.show, &.collapsing {
      .nav-text {
        display: unset;
      }

      .nav-icon {
        width: 2rem;
        display: inline-block;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 1680px) {
  .navbar-icon-first .nav-text {
    display: none;
  }
}

@media only screen and (max-width: 1600px) {
  .navbar-icon-second .nav-text {
    display: none;
  }
}

@media only screen and (max-width: 1540px) {
  .navbar-icon-third .nav-text{
    display: none;
  }
}

@media only screen and (max-width: 1460px) {
  .navbar-icon-fourth .nav-text {
    display: none;
  }
}

@media only screen and (max-width: 1380px) {
  .navbar-icon-fifth .nav-text {
    display: none;
  }
}

@media only screen and (max-width: 1260px) {
  .navbar-icon-sixth .nav-text {
    display: none;
  }
}

@media only screen and (max-width: 1160px) {
  .navbar-icon-seventh .nav-text {
    display: none;
  }
}

.navbar {
  .user-info {
    display: inline-flex;
    flex-direction: column;
    max-height: 35px;
    justify-content: space-around;

    .user-info-name {
      text-align: right;
    }

    .user-info-organization {
      font-size: .8rem;
      text-align: right;
    }
  }
}

.dropdown-submenu {
  position: relative;

  a::after {
    transform: rotate(-90deg);
    position: absolute;
    right: 6px;
    top: 1em;
  }

  .dropdown-menu {
    top: 0;
    left: -100%;
    width:100%;
    margin-left: .1rem;
    margin-right: .1rem;
  }
}

.navbar {
  .navbar-stretch {
    justify-content: stretch;
    align-items: stretch;
  }
  .dropdown-item-wrap {
    white-space: normal;
  }
}


.logged-in-as-someone-else {
  background-color: #d32f2f;
  line-height: 3rem;
  text-align: center;
  width: 100%;
  z-index: 99999;
  border-bottom: 4px solid #b71c1c;
  top: 0;
  height: 3rem;

  a {
    color: #ffebee !important;
  }

  box-shadow: 0 -50px 25px 50px;
}

.logged-in-as-someone-else-bg {
  height: 1.5rem;
}

.navbar-brand {
  display: flex;
  padding: 0.125rem;
  margin: 0 1rem 0 1rem;

  > .img-container {
    position: relative;
    display: inline-block;
    overflow: hidden;

    width: 8rem;

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}

.bg-factory-lab {
  background-color: $factory-lab;
}

.img-avatar {
  @extend .rounded-circle;
  @extend .img-thumbnail;
  padding: 0;
  height: 24px;
  width: 24px;
  object-fit: contain;
  overflow: clip;
}

.img-avatar-lg {
  @extend .rounded-circle;
  @extend .img-thumbnail;
  padding: 0;
  height: 38px;
  width: 38px;
  object-fit: contain;
}

.dropdown-menu .dropdown-item.user-information {
  padding: map-get($spacers, 1) map-get($spacers, 3);

  white-space: normal;

  h5 {
    font-size: 11px;
  }

  h4 {
    margin-bottom: 0;
    font-size: 14px;
    small {
      font-size: 11px;
    }
  }

  .account-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .account-avatar {
      margin-right: map-get($spacers, 2);
    }

    .account-info {
      flex: 1;
    }
  }
}

.organization-info {
  display: flex;
  flex-direction: row;
  align-items: center;

  .organisation-name {
    flex: 1;
    white-space: normal;
  }

  .organization-logo {
    width: 64px;
    margin-left: map-get($spacers, 2);
    img {
      width: 100%;
      max-height: 64px;
      object-fit: contain;
      object-position: right center;
    }
  }
}

.dropdown-item {
  user-select: none;
}