@import "../variables";
@import "~bootstrap/scss/mixins";

.btn[data-toggle="collapse"] {
  .fa.fa-chevron-down {
    transition: $transition-collapse;
    transition-property: transform;
    transform: rotate(180deg);
  }

  &.collapsed .fa.fa-chevron-down {
    transform: rotate(0deg);
    filter: none;
  }
}

.btn-bulk {
  height: 100%;
  display: block;
  border: 1px solid #E0E0E0;
  box-shadow: 0 1px 1px rgba(177, 177, 177, 0.25), inset 0 1px 0 rgba(255, 255, 255, .25);
  border-radius: 1rem;
  margin: 1rem 0;
  padding: 1.5rem 1.5rem 0;

  &__title {
    margin: 0;
  }

  &__icon {
    width: calc(100% + 2rem);
    margin: 0 -1rem 1rem;

    img {
      max-width: 210px;
      width: 100%;
    }
  }

  &:hover {
    background-color: rgb(227, 233, 241);
    box-shadow: 1px 1px 8px rgba(18, 78, 150, .17);
  }

  &:active,
  &:focus {
    background-color: rgb(199, 213, 226);
    box-shadow: inset 2px 2px 7px rgba(18, 78, 150, .07);
  }
}

.btn-disabled {
  @include button-variant($gray-500, $black)
}
