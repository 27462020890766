@use "sass:color";

@import 'variables';
@import "~bootstrap/scss/bootstrap";

@import "modules/alert";
@import "modules/awesome-bootstrap-checkbox";
@import "modules/background";
@import "modules/badge";
@import "modules/bottom-bar";
@import "modules/buttons";
@import "modules/card";
@import "modules/form";
@import "modules/indicators";
@import "modules/links";
@import "modules/modal";
@import "modules/nav";
@import "modules/navbar";
@import "modules/polyfill";
@import "modules/positioning";
@import "modules/progress";
@import "modules/questionnaire";
@import "modules/row";
@import "modules/select2";
@import "modules/table";
@import "modules/text";
@import "modules/tokenify";
@import "modules/widget";
@import "modules/user_profile";

.bg-info-light {
  background-color: theme-color-level("info", -9);
}

html {
  font-size: $root-font-size;
  scroll-behavior: smooth;
}

@each $identifier, $spacer in ("0": "1px", "1": "2px", "2": "3px", "3": "5px", "4": "8px") {
  .border-size-#{$identifier} {
    border-width: #{$spacer} !important;
  }
}

@each $identifier, $spacer in $spacers {
  .gap-#{$identifier} {
    gap: #{$spacer} !important;
  }
}

.todo-icon {
  position: relative;

  .special {
    right: -9px;
    top: -4px;
    position: absolute;
    padding: .125rem .375rem;
    border-radius: 10px;
  }
}

.col {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      $width: breakpoint-min($breakpoint, $grid-breakpoints);
      &.col-responsive, &.col-responsive-small {
        flex-basis: unset;
        flex-grow: 0;
        flex-shrink: 0;
      }
      @if $width {
        &.col-responsive {
          width: $width * .4;
        }
        &.col-responsive-small {
          width: $width * .3;
        }
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        &.col-responsive, &.col-responsive-small {
          flex-basis: 0;
          flex-grow: 1;
          flex-shrink: 1;
        }

        &.col-responsive {
          @include make-col(3, $grid-columns);
        }
        &.col-responsive-small {
          @include make-col(2, $grid-columns);
        }
      }
    }
  }

  min-width: 0;
}

@each $theme, $color in $theme-colors {
  .card.text-#{$theme} {
    > .table {
      color: $color;
    }
  }
}

.page-title {
  font-size: 1rem;
  margin-bottom: 0;
}

#locationContainer {
  a.list-group-item {
    .location-icon {
      display: inline-block;
      height: 1em;
      width: 1em;
      margin-right: 5px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

.btn-default {
  border-color: darken($default, 15%);

  &.disabled, &:disabled {
    border-color: darken($default, 15%);
  }
}

.btn-transparent {
  background-color: transparent;
  color: $primary;
  outline-width: 1px;
  outline-offset: 0;

  &:active,
  &:hover {
    color: $primary;
  }

  &.active {
    background-color: $fancy-component-active-bg;
    box-shadow: none !important;
  }

  &:hover {
    background-color: $fancy-component-hover-bg;
  }
}


.row > hr {
  flex: 1
}

.form-group {
  > label, &.row > div > label {
    font-weight: 500;
  }

  &.row > div > label {
    line-height: $input-height;
  }

  label ~ .help-block {
    margin-bottom: $input-height-inner-quarter;
  }
}

.craue_formflow_buttons {
  // The buttons are also rendered as a row, so the margins are correct again.
  @extend .row;
}

textarea.no-resize-horizontal {
  resize: vertical;
}

.card_selector {
  min-width: 15rem;
  max-width: 25rem;

  &.in_active {
    background-color: $gray-400;
  }

  &.active {
    @extend .bg-success;
    @extend .text-white;

    > .table {
      color: $white;
    }
  }
}

caption {
  caption-side: top;
}

.card-header {
  > .btn, > .btn-group, > .row > div > .btn, > .row > div > .btn-group, > div.float-right {
    &.float-right, &.float-left {
      margin-top: -#{map_get($spacers, 1)};
      margin-bottom: -#{map_get($spacers, 1)};
    }

    margin-top: -#{map_get($spacers, 3)};
    margin-bottom: -#{map_get($spacers, 2)};
  }
}

body.no-animations * {
  animation-duration: 0s !important;;
  transition: all 0s !important;
}

.list-group-subitem {
  @extend .list-group-item;
  padding-left: map_get($spacers, 4);
}

.list-group-item.list-group-item-header {
  background-color: adjust_color($card-cap-bg, $alpha: -.058);
}

[data-toggle="collapse"] {
  cursor: pointer;
}

.card-body hr.flush {
  margin-left: -#{$card-spacer-x};
  margin-right: -#{$card-spacer-x};
}

.card-body > div > div.table-responsive.flush {
  margin-left: -#{$card-spacer-x};
  margin-right: -#{$card-spacer-x};
  width: calc(100% + #{$card-spacer-x} * 2)
}

ul, ol {
  &.list-flush {
    padding-inline-start: map_get($spacers, 3);
  }
}

.input-group-inline {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  .input-group-prepend, .input-group-append {
    position: absolute;
    top: 0;
    bottom: 0;

    .input-group-text {
      background-color: transparent;
      border: 0;
      color: $gray-600;
    }

    width: 2rem;
  }

  .input-group-prepend {
    left: 0;
  }

  .input-group-append {
    right: 0;
  }

  .input-group-prepend ~ .form-control {
    padding-left: 2.5rem;
  }

  .form-control ~ .input-group-append {
    padding-right: 2.5rem;
  }
}

.list-group-item {
  &.value-heuristic-1, &.value-heuristic-2, &.value-heuristic-3, &.value-heuristic-4, &.value-distance {
    background-color: theme_color-level("warning", $alert-bg-level);
    border-color: theme_color-level("warning", $alert-border-level);
    color: theme_color-level("danger", $alert-color-level);
  }
}

//override for highcharts styling
.highcharts-container {
  z-index: auto !important;
  overflow: visible !important;
}

// debug information :-)
table.table.table-responsive {
  position: relative;

  &::after {
    content: "Gebruik de table-responsive op een parent div van deze, niet op de tabel zelf ;)";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2rem;
    font-size: 1.5rem;
    $adjusted: adjust-color(theme_color("secondary"), $alpha: -.3);
    color: color-yiq($adjusted);

    backdrop-filter: blur(10px);
    background-color: $adjusted;

  }
}

// Debugging purposes, make sure that the twig template extends the scripts

body.extend_scripts {
  &::after {
    content: 'When extending the "scripts" tag, also make sure to include the "parent()" call!';

    $adjusted: adjust-color(theme_color("primary"), $alpha: -.3);
    color: color-yiq($adjusted);

    backdrop-filter: blur(10px);
    background-color: $adjusted;
    position: fixed;

    text-align: center;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    z-index: 9999;

    font-size: 3rem;

    line-height: 30rem;
  }
}

.form-check.abc-checkbox {
  margin-bottom: $input-height-inner-quarter;

  .list-group-item & {
    margin-bottom: 0;

    label {
      padding-left: 1rem;
    }
  }
}

.explanation {
  position: relative;
  bottom: map_get($spacers, 1);
  font-size: .75rem;
}

.navbar-location {
  .address-container {
    padding-right: 1.875rem;
  }

  .ra-location-icon-container {
    position: absolute;
    right: 0;
    height: 1.875rem;
    width: 1.875rem;
  }
}

img.ra-location-icon {
  height: 1.875rem;
  width: 1.875rem;
}

#drop_shipment_suggestedItems_container {
  &,
  & .card {
    margin: 0 0 1rem;
  }
}

div[data-toggle="collapse"] {
  .fa.fa-chevron-down {
    transition: $transition-collapse;
    transition-property: transform;
    transform: rotate(180deg);
  }

  &.collapsed .fa.fa-chevron-down {
    transform: rotate(0deg);
    filter: none;
  }
}

.sky-light-bg {
  background: var(--Utility-Information-lighter, #50C1EF29);
}

.node-group {
  display: flex;
  flex-direction: row;
  gap: 0.375rem;

  margin-bottom: 0.375rem;

  border-bottom: 1px solid gray;
  align-items: flex-start;
  justify-content: center;
}

.suggestion-box {
  position: absolute;
  top: 2.375rem;
  right: 0.5rem;
  width: 60rem;

  z-index: 999;

  .card {
    box-shadow: 0 3px 15px 5px rgba(0, 0, 0, 0.14);
  }
}
