.user-card {
  display: inline-grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  background-color: $gray-200;
  border: 1px solid $gray-300;
  border-radius: 10rem;
  gap: map-get($spacers, 2);
  padding-left: map-get($spacers, 2);
  padding-right: map-get($spacers, 2);

  img {
    @extend .rounded-circle;
    padding: 0;
    min-width: 24px;
    height: 24px;
    width: 24px;
    object-fit: contain;
    overflow: clip;
  }
}
