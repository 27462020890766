@import "../variables";

.card > .table.table-bordered {
  border-left: 0;
  border-right: 0;

  th, td {
    &:first-child {
      border-left: 0;
    }

    &:last-child {
      border-right: 0;
    }
  }
}

.table {
  > tbody {
    > tr {
      > td {
        h1, h2, h3, h4, h5, h6 {
          &:last-child {
            margin-bottom: 0;
            margin-top: map_get($spacers, 1);
          }
        }
      }
    }
  }
}

table {
  tr.vert-align > td {
    vertical-align: middle;
  }

  td {
    &.vert-align {
      vertical-align: middle;
    }
  }

  td, tr {
    @each $theme, $color in $theme-colors {
      &.#{$theme} {
        background-color: theme_color_level($theme, $alert-bg-level);
        color: theme_color_level($theme, $alert-color-level);
      }
    }
  }

  &.table-striped {
    tr {
      @each $theme, $color in $theme-colors {
        &.#{$theme} {
          $adjusted-color: scale_color($color, $lightness: 85%);

          &:nth-of-type(odd) {
            $odd-color: scale_color($adjusted-color, $lightness: -5%);
            background-color: $odd-color;
            color: color-yiq($odd-color);
          }
        }
      }
    }
  }

  &.table-hover {
    tr:hover {
      @each $theme, $color in $theme-colors {
        &.#{$theme}, td.#{$theme} {
          $adjusted-color: scale_color($color, $lightness: 85%);
          $hover-color: scale_color($adjusted-color, $lightness: -10%);
          background-color: $hover-color;
          color: color-yiq($hover-color);
        }
      }
    }
  }
}

.table-md {
  td, th {
    padding: .6rem;
  }
}

td > ul, td > span.value > ul {
  &:not(.ui-autocomplete) {
    padding-inline-start: 1rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

td > dl, td > span.value > dl {
  margin-bottom: 0;
}

.table.table-vertical-align-center {
  tbody th, td {
    vertical-align: middle;
  }
}

th {
  &.sorting_ {
    &asc, &desc {
      background-color: $table-hover-bg;
    }
  }
}

.table-responsive-shadow {
  pointer-events: none;
  &.shadow-before {
    background: linear-gradient(90deg, rgba(0,0,0,.3) 0%, rgba(0,0,0,0) 100%);
    opacity: 0;
    transition: opacity 325ms ease-in-out;
    &.show {
      opacity: 1;
    }
  }

  &.shadow-after {
    background: linear-gradient(270deg, rgba(0,0,0,.3) 0%, rgba(0,0,0,0) 100%);
    opacity: 0;
    transition: opacity 325ms ease-in-out;
    &.show {
      opacity: 1;
    }
  }
}


.tasks-table {
  td {
    .float-right + .float-right {
      margin-right: 0.175rem;
    }
  }
}

.table {
  th[data-header="last-online"] {

    &:not(.loading) .fa-spinner {
      display: none;
    }

    &.loading {
      pointer-events: none;
      position: relative;

      .fa-spinner {
        position: absolute;
        right: 10px;
        top: 12px;
      }
    }
  }
}