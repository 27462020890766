@import '../variables';

input.form-check-lg {
  width: 1.5rem;
  height: 1.5rem;
}

fieldset {
  legend {
    border-bottom: 1px solid $gray-300;
    background-color: $card-bg;
  }
}

.form-check-expanded {
  &.is-valid ~ .valid-feedback {
    display: block;
  }

  &.is-invalid ~ .invalid-feedback {
    display: block;
  }

  &.is-invalid {
    color: $form-feedback-invalid-color;
  }

  &.is-valid {
    color: $form-feedback-valid-color;
  }
}

.form-check + .form-check {
  margin-top: 0.5rem;
}

.form-check {
  &.abc-checkbox, &.abc-radio {
    label {
      user-select: none;
      padding-top: 2px;
    }
  }
}

.inline-icon-container {
  position: relative;

  .inline-icon {
    position: absolute;
    top: 0;
    right: 0;
  }

  .form-control ~ .inline-icon {
    top: $input-padding-y;
    right: $input-padding-x;
    font-size: $input-font-size;
    line-height: $input-line-height;
  }

  .form-control-lg ~ .inline-icon {
    top: $input-padding-y-lg;
    right: $input-padding-x-lg;
    font-size: $input-font-size-lg;
    line-height: $input-line-height-lg;
  }

  .form-control-sm ~ .inline-icon {
    top: $input-padding-y-sm;
    right: $input-padding-x-sm;
    font-size: $input-font-size-sm;
    line-height: $input-line-height-sm;
  }
}

.custom-file-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.form-label-lg {
  font-size: $input-font-size-lg;
}

.datetime-edit-field,
.datetime-input-box-wrapper,
input::-webkit-datetime-edit-fields-wrapper {
  text-transform: uppercase;
}

.form-control[autocomplete="one-time-code"] {
  --spacing: 1.25ch;
  --size: 1.75rem;

  font-family: ui-monospace, monospace;
  font-size: var(--size);
  letter-spacing: var(--spacing);
  width: calc(6 * (var(--size) + var(--spacing)));

  &::placeholder {
    color: $gray-400;
  }
}

form[name="component_move_save_form"] {
  &.is-submitting {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 1000;
    }
  }
}
