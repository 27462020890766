@import "../../variables";
@import "~bootstrap/scss/mixins";

.nav.nav-tabs {
  @each $theme, $color in $theme-colors {
    > .nav-item.nav-item-#{$theme} {
      > a {
        background-color: theme-color-level($theme, -11);
        border-color: theme-color-level($theme, -9);
        color: theme-color-level($theme, $alert-color-level);

        &.active {
          background-color: theme-color-level($theme, -9);
        }
        &:hover {
          background-color: theme-color-level($theme, -10);
        }
      }
    }
  }
}

.nav.nav-tabs {
  z-index: 1021;

  .nav-link {
    &.active {
      background-color: transparent;
      border-bottom-color: white;
    }
  }

  .dropdown-menu {
    .dropdown-item {
      &.active {
        position: relative;
        &:before {
          content: '•';
          font-size: 1.5em;
          left: .375em;
          position: absolute;
          top: 0;
        }
      }
    }
  }
}