@import "../variables";

@each $identifier, $color in $theme-colors {
  .modal.modal-#{$identifier} {
    .modal-header {
      background-color: $color;
      color: color-yiq($color);

      .close {
        color: color-yiq($color);
      }
    }
  }
}

.modal {
  scroll-behavior: smooth;
}

.modal-content {
  border: none;
  img {
    max-width: 100%;
  }
}

.modal-fluid {
  @extend .mw-100;

  width: 95% !important;
}

.modal-content .modal-footer {
  position: sticky;
  bottom: 0;
  background-color: $modal-content-bg;
  z-index: 10;
}