@import "../variables";
@import "~select2/src/scss/core";
@import "~bootstrap/scss/mixins/forms";

$s2bs-btn-default-border: $gray-500;

@import "~select2-theme-bootstrap4/src/select2-bootstrap";


.select2-dropdown {
  z-index: 1056; // ensure it's above bootstrap modals
}

@mixin validation-state-styling($color, $icon) {
  border-color: $color;

  @if $enable-validation-icons {
    padding-right: add($input-height-inner, $input-height-inner-half);
    background-image: escape-svg($icon);
    background-repeat: no-repeat;
    background-position: right add($input-height-inner-quarter, $input-height-inner-half) center;
    background-size: $input-height-inner-half $input-height-inner-half;
  }

  &:focus {
    border-color: $color;
    box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
  }
}

.select2 {
  @include form-validation-state-selector("valid") {
    + .select2 .select2-selection {
      @include validation-state-styling($form-feedback-valid-color, $form-feedback-icon-valid);
    }
  }

  @include form-validation-state-selector("invalid") {
    + .select2 .select2-selection {
      @include validation-state-styling($form-feedback-invalid-color, $form-feedback-icon-invalid);
    }
  }
}

.select2-container--bootstrap .select2-results__option--selected {
  background-color: $s2bs-dropdown-link-hover-bg;
  color: $s2bs-dropdown-link-hover-color;
}

.select2-results .select2-results__options {
  overscroll-behavior: contain;
}

.select2-container .select2-results .select2-results__options--nested {
  .select2-results__option {
    padding-left: map_get($spacers, 3);
  }
}

.select2-container--bootstrap {
  .select2-selection--multiple  {
    .select2-selection__choice__remove {
      border: 0;
      background-color: transparent;
      padding-left: map_get($spacers, 1);
      padding-right: map_get($spacers, 1);
    }
  }

  .select2-results__option {
    &[aria-disabled=true] {
      background-color: $input-disabled-bg;
    }
  }
}
