@import "./mixins";
@import "./variables";

.tokenfield-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: $tokenfield-margin-left;
  margin-right: $tokenfield-margin-right;

  padding: 0 $tokenfield-padding-right $tokenfield-padding-y $tokenfield-padding-left;
  font-size: $tokenfield-font-size;
  font-weight: $tokenfield-font-weight;
  color: $tokenfield-color;
  background-clip: padding-box;
  border: $tokenfield-border-size $tokenfield-border-style $tokenfield-border-color;
  border-radius: $tokenfield-border-radius;
  box-shadow: $tokenfield-box-shadow;
  transition: $tokenfield-transition;

  background-color: $tokenfield-bg;

  &:not(.disabled) {
    cursor: text;
  }

  &.disabled {
    color: $tokenfield-disabled-color;
    background-color: $tokenfield-disabled-bg;

    .token {
      cursor: not-allowed;
      opacity: $tokenfield-disabled-token-opacity;
    }
  }

  &.focussed {
    color: $tokenfield-focus-color;
    background-color: $tokenfield-focus-background-color;
    border-color: $tokenfield-focus-border-color;
    //outline: $tokenfield-focus-outline;
    box-shadow: $tokenfield-focus-shadow;
  }

  .token {
    margin-top: $tokenfield-padding-y;
    cursor: pointer;
    margin-right: $tokenfield-padding-right;
    background-color: $tokenfield-token-background-color;
    padding: $tokenfield-token-padding-y $tokenfield-token-padding-x;
    border: $tokenfield-token-border-size solid $tokenfield-token-border-color;
    border-radius: $tokenfield-border-radius;

    transition: $tokenfield-token-transition;
    transition-property: background-color;

    &::after {
      content: "\00d7";
      margin-left: .25rem;
    }
  }

  &:not(.disabled) .token {

    &:hover {
      background-color: $tokenfield-token-hover-background-color;
    }
  }

  input {
    flex: 1;
    min-width: 2em;
  }

  input, input:focus {
    margin-top: $tokenfield-padding-y;
    border: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    outline: 0;
  }
}