@import "../variables";


.widget {

  .widget-row {

    .widget-name-column {
      text-decoration: none;
      word-break: break-all;
    }

    .download-icon {
      display: none;
      text-align: center;

      .fa {
        transform: scale(1.375);
      }
    }

    &:hover {
      cursor: pointer;

      .download-link {
        text-decoration: none;
      }

      .date {
        display: none;
      }

      .download-icon {
        display: block;
      }
    }

  }

  .card-footer {
    background: transparent;
  }

  .widget-number-large {
    text-align: center;
    font-size: 1.875rem;
  }

  &.client-organization-logo-widget {
    align-content: center;
  }

  &.client-organization-logo-widget .image-thumbnail-anchor {
    pointer-events: none;
  }
  
  &.client-organization-logo-widget .image-thumbnail-anchor img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}

.loader-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  background-color: #F4F4F455;
  backdrop-filter: blur(3px);
  z-index: 100;
  align-items: center;
}
