@import "../variables";

.card-footer.card-footer-sticky {
  position: sticky;
  bottom: -1px;
}

.card {
  overflow-wrap: normal;
  word-break: normal;

  .card-header + .card-table, .card-header + .table-responsive {
    border-top: 0;

    .table-bordered th, th {
      border-top: 0;
    }
  }

  .card-body {
    .card-row {
      @extend .row;
      margin-left: -#{$card-spacer-x};
      margin-right: -#{$card-spacer-x};
    }
  }

  > .table:last-child, > .table-responsive:last-child .table {
    margin-bottom: 0;
  }
}

.card-footer {
  background-color: adjust_color($card-cap-bg, $alpha: -.05);

  &.card-footer-sticky {
    background-color: rgba(244, 244, 244, 1);
  }
}

.card-header {
  > .row {
    align-items: center;
  }
}