@import "../variables";

.is--migrated .badge {
  font-size: 100%;
}

.badge-disabled {
  background-color: $gray-500;
  color: color-yiq($gray-500, $black, $white);
}

.badge-sm {
  font-size: $font-size-sm;
}

.badge-light {
  background-color: $primary-light;
  color: $primary-dark;
}

.badge-lightest {
  background-color: $primary-lightest;
  color: $primary-dark;
}

.badge-rounded {
  border-radius: 4px;
}
