@import '../variables';

.text-word-wrap {
  white-space: normal;
  word-break: normal;
}

blockquote {
  border-left: 0.25rem solid $primary;
  padding-left: 0.875rem;

  cite {
    font-size: 1rem;
  }
}

.text-gray- {
  @each $level, $color in $grays {
    &#{$level} {
      color: $color;
    }
  }
}

.fs- {
  @each $level, $size in $text-sizes {
    &#{$level} {
      font-size: $size;
    }
  }
}