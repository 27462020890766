.card .sticky-top {
  z-index: 10;
}
.card .card-footer-sticky {
  z-index: 11;
}

.sticky-top {
  top: -1px; // Prevents a gap of 1/0.7px to be shown in the top
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 11;
}

.ts-dropdown {
  z-index: 1050 !important;
}
