@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

  .card-deck {
    width: 100%;
  }

  // fixes stretched images in cards
  .card-deck .card {
    display: block;
  }

  // fixes stretched images in cards
  .card-group .card {
    display: block;
  }

  .card .card-text {
    width: 100%;
  }
}
