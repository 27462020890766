@import "../variables";
@import "~@fortawesome/fontawesome-free/scss/functions";
@import "~@fortawesome/fontawesome-free/scss/variables";

$font-family-icon: 'Font Awesome 6 Free';

$check-icon: "\f00c";

@import "~awesome-bootstrap-checkbox/awesome-bootstrap-checkbox";

.abc-checkbox {
  input[type="checkbox"],
  input[type="radio"] {
    &:checked + label::after {
      font-weight: 900;
    }
  }
}