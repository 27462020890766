@import "variables";
@import "../base/style";
@import "~bootstrap/scss/mixins";

//  Cover layout
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .gradient-wallpaper {
    background-image: url("background/waves.svg");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: 100%;
      background-image:
              radial-gradient(circle farthest-side at top left, #32a8d6, rgba(#124e96, .87));
      ;
    }

    @include media-breakpoint-up(xl) {
      background-image: url("background/waves-lg.svg");
    }
  }
}

@media(min-height: 600px) {
  .cover-container {
    position: relative;

    .image-logo {
      position: absolute;
      bottom: 90%;
      opacity: 1;
      animation: peekabo;
      animation-duration: 2s;
      animation-fill-mode: forwards;
      left: 0;
      right: 0;
    }

    .card {
      border-radius: .5rem;
    }
  }
}

@keyframes peekabo {
  0% {
    bottom: 0%;
    opacity: 0;
  }

  100% {
    bottom: 90%;
    opacity: 1;
  }
}
