@import "../variables";

$bottom-bar-spacer-y: $card-spacer-y !default;
$bottom-bar-spacer-x: $card-spacer-x !default;

$bottom-bar-border-width: $card-border-width !default;
$bottom-bar-border-color: $card-border-color !default;

$bottom-bar-background-color: $white !default;
$bottom-bar-color: color-yiq($bottom-bar-background-color) !default;

.multi-select-bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $zindex-sticky;

  padding: $bottom-bar-spacer-y $bottom-bar-spacer-x;

  background-color: $bottom-bar-background-color;
  color: $bottom-bar-color;
  border-top: $bottom-bar-border-width solid $bottom-bar-border-color;

  .bottom_bar_submenu_container {
    position: relative;

    .bottom_bar_submenu {

      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;

      min-width: 100%;
      bottom: 0;
      left: -#{map_get($spacers, 2)};
      padding: $card-spacer-y $card-spacer-x;
      background-color: $bottom-bar-background-color;
      position: absolute;
      border: $bottom-bar-border-width solid $bottom-bar-border-color;
      border-bottom: 0;
    }
  }
}